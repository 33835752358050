
import Society from "@/models/societymgmt/Society";
import ClubTeamDisciplineResult from "@/models/sportevent/ClubTeamDisciplineResult";
import ClubTeamResults from "@/models/sportevent/ClubTeamResults";
import DisciplineResult from "@/models/sportevent/DisciplineResult";
import Sportevent from "@/models/sportevent/Sportevent";
import SporteventResult from "@/models/sportevent/SporteventResult";
import Starter from "@/models/sportevent/Starter";
import DisciplineCategory from "@/models/sporteventCalculation/DisciplineCategory";
import ClubTeamResultService from "@/services/sportevent/ClubTeamResultService";
import SporteventResultService from "@/services/sportevent/SporteventResultService";
import SporteventService from "@/services/sportevent/SporteventService";
import StarterService from "@/services/sportevent/StarterService";
import { Component, Vue } from "vue-property-decorator";

class CompetitionCategoryResult {
  public points: number = 0;
  public disciplineCategory: DisciplineCategory = new DisciplineCategory();
  public sporteventResults: SporteventResult[] = [];
  public clubTeamDisciplineResults: ClubTeamDisciplineResult[] = [];
}

class ClubTeamResultList {
  public society: Society = new Society();
  public rank: number = 0;
  public totalPoints: number = 0;
  public competitionCategoryResults: CompetitionCategoryResult[] = [];
}

@Component
export default class ListResultsClubCompetitionComponent extends Vue {
  public loading = true;
  public minimumTeamStarters = 8;
  public sportevent: Sportevent = new Sportevent();
  public allDisciplineCategories: DisciplineCategory[] = [];
  public allSporteventResult: SporteventResult[] = [];
  public allSocieties: Society[] = [];
  public allClubTeamResultList: ClubTeamResultList[] = [];
  public allClubTeamResults: ClubTeamResults[] = [];

  async mounted(): Promise<void> {
    this.loading = true;
    this.sportevent = SporteventService.get();
    this.sportevent.sporteventCompetitions.forEach((sporteventCompetition) => {
      sporteventCompetition.disciplines.forEach((discipline) => {
        const foundIndex = this.allDisciplineCategories.findIndex(
          (disciplineCategory) =>
            disciplineCategory.id === discipline.disciplineCategory.id
        );
        if (foundIndex === -1) {
          this.allDisciplineCategories.push(discipline.disciplineCategory);
        }
      });
    });
    // sort by name
    this.allDisciplineCategories = this.allDisciplineCategories.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    this.allSocieties = (await StarterService.getAll())
      .map((starter) => starter.startsForSociety)
      .filter((society, index, self) => {
        return (
          index ===
          self.findIndex((s) => s.id === society.id && s.name === society.name)
        );
      });
    this.allClubTeamResults = await ClubTeamResultService.getAllBySportevent();
    if (this.sportevent.id !== undefined) {
      this.allSporteventResult =
        await SporteventResultService.getAllBySportevent(this.sportevent.id);
    }

    this.allSocieties.forEach((society: Society) => {
      const clubTeamResultList = new ClubTeamResultList();
      const clubTeamResults = this.allClubTeamResults.filter(
        (ctr) => ctr.society.id === society.id
      );
      clubTeamResultList.society = society;
      this.allDisciplineCategories.forEach(
        (disciplineCategory: DisciplineCategory) => {
          const competitionCategoryResult = new CompetitionCategoryResult();
          competitionCategoryResult.disciplineCategory = disciplineCategory;

          // Einzel Aufgaben!
          let disciplineResultForCategory: DisciplineResult[] = [];
          this.allSporteventResult
            .filter((sr) => sr.starter.startsForSociety.id === society.id)
            .forEach((sporteventResult) => {
              sporteventResult.disciplineResults.forEach((disciplineResult) => {
                if (
                  disciplineResult.discipline.disciplineCategory.id ===
                  disciplineCategory.id
                ) {
                  disciplineResultForCategory.push(disciplineResult);
                }
              });
            });

          disciplineResultForCategory = disciplineResultForCategory.sort(
            (a, b) => b.calculatedPoints - a.calculatedPoints
          );
          console.log(disciplineResultForCategory);
          let teamResultCount = disciplineResultForCategory.length;

          let count = 0;
          if (teamResultCount >= 8) {
            if (teamResultCount >= 10) {
              teamResultCount = teamResultCount - 2;
            }
            disciplineResultForCategory.forEach((disciplineResult) => {
              if (
                count < teamResultCount &&
                disciplineResult.calculatedPoints > 0
              ) {
                count++;
                competitionCategoryResult.points +=
                  disciplineResult.calculatedPoints;
              }
            });
          }

          console.log(disciplineResultForCategory);
          if (disciplineResultForCategory.length >= 8) {
            if (competitionCategoryResult.points > 0) {
              competitionCategoryResult.points = Number(
                (competitionCategoryResult.points / count).toFixed(3)
              );
            }
          }

          // Team Aufgaben!
          clubTeamResults.forEach((clubTeamResult) => {
            clubTeamResult.clubTeamDisciplineResults.forEach(
              (clubTeamDisciplineResult) => {
                if (
                  clubTeamDisciplineResult.sporteventCompetition.disciplines.findIndex(
                    (d) => d.disciplineCategory.id === disciplineCategory.id
                  ) > -1
                ) {
                  competitionCategoryResult.points +=
                    clubTeamDisciplineResult.points;
                  competitionCategoryResult.clubTeamDisciplineResults.push(
                    clubTeamDisciplineResult
                  );
                }
              }
            );
          });

          clubTeamResultList.competitionCategoryResults.push(
            competitionCategoryResult
          );
        }
      );
      this.allClubTeamResultList.push(clubTeamResultList);
    });
    this.allClubTeamResultList.forEach((clubTeamResultList) => {
      clubTeamResultList.competitionCategoryResults.forEach(
        (competitionCategoryResult) => {
          clubTeamResultList.totalPoints += competitionCategoryResult.points;
        }
      );
      clubTeamResultList.totalPoints = Number(
        clubTeamResultList.totalPoints.toFixed(3)
      );
    });
    this.allClubTeamResultList = this.allClubTeamResultList.sort(
      (a, b) => b.totalPoints - a.totalPoints
    );
    for (let rank = 1; rank <= this.allClubTeamResultList.length; rank++) {
      if (
        rank > 1 &&
        this.allClubTeamResultList[rank - 1].totalPoints ===
          this.allClubTeamResultList[rank - 2].totalPoints
      ) {
        this.allClubTeamResultList[rank - 1].rank =
          this.allClubTeamResultList[rank - 2].rank;
      } else {
        this.allClubTeamResultList[rank - 1].rank = rank;
      }
    }

    this.loading = false;
  }
}
